import React, { useState } from 'react'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import Img from 'gatsby-image'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { Modal } from 'src/components/Modal'
import ModalAccount from 'src/components/UI/Forms/OpenAccountRightForm'
import useDomReady from 'src/hooks/window/useDomReady'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

import QrCode from '../../assets/qr-code-investimentos-plataformaglobal.png'
import QrCodeKids from '../../assets/qrcode-conta-kids-plataforma-global-investimentos.png'

import dataOrange from '../../assets/data/data.json'
import { DataContent, ButtonText } from './styles'
import { orange } from 'src/styles/colors'

import usePageQuery from '../../pageQuery'
import ToolTip from './ToolTip/_ToolTip'
interface IDataOrangeProps {
  icon: string;
  text: string;
  hasLink: string;
  linkText: string;
  hasTooltip?: boolean;
}

const Advantages = () => {
  const images = usePageQuery()
  const domReady = useDomReady()
  const [ isOpen, setIsOpen ] = useState(false)
  const [ sendDatalayerEvent ] = useDataLayer()

  const data: IDataOrangeProps[] = dataOrange

  const [ dataLayer, setDataLayer ] = useState<IDataLayerParams>({
    section: 'dobra_02',
    section_name: 'Vantagens em investir na Plataforma Global de Investimentos do Inter',
    element_action: 'click button',
    element_name: 'Abra a sua conta',
  })

  const openModal = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <ModalAccount
        closeModal={() => setIsOpen(false)} dataLayer={dataLayer}
        qrBaixeApp={QrCode}
        qrCodeContaKids={QrCodeKids}
      />
    </Modal>
  )

  return (
    <>
      {openModal}
      <section id='vantagens-em-investir' className='bg-grayscale--100 pb-4 pt-5 pb-md-5'>
        <div className='container'>
          <div className='row align-items-center justify-content-between'>
            <div className='col-12 col-md-6 col-lg-5 d-none d-md-flex'>
              <Img fluid={images.AdvantagesImage.fluid} alt='Imagem de um gráfico de investimentos' />
            </div>
            <div className='col-12 col-md-6 col-xxl-5 mb-2 mb-md-0'>
              <h2 className='mb-4 fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500'>
                Vantagens em investir na Plataforma Global de Investimentos do Inter
              </h2>
              <p className='mb-4 fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--500'>
                Aqui, você diversifica a sua carteira e tem acesso as maiores empresas do mundo.
                <a
                  className='d-flex d-md-none'
                  href='https://inter-co.onelink.me/Qyu7/715degyr'
                  onClick={() => {
                    setDataLayer(dataLayer)
                    sendDatalayerEvent(dataLayer)
                  }}
                > Abra a sua conta
                </a>
                <ButtonText
                  className='d-none d-md-inline'
                  onClick={() => {
                    setIsOpen(true)
                    setDataLayer(dataLayer)
                    sendDatalayerEvent(dataLayer)
                  }}
                > Abra a sua conta
                </ButtonText> e aproveite os benefícios.
              </p>
              {data.map((item: IDataOrangeProps, index: number) => (
                <DataContent key={index} className='d-flex align-items-center mb-3'>
                  <div className='mr-3'>
                    <OrangeIcon icon={item.icon} size='MD' color={orange.extra} />
                  </div>
                  {item.hasLink ? (
                    <div
                      onClick={() => {
                        setDataLayer(dataLayer)
                        sendDatalayerEvent({
                          section: 'dobra_02',
                          section_name: 'Vantagens em investir na Plataforma Global de Investimentos do Inter',
                          element_action: 'click button',
                          element_name: item.linkText,
                          redirect_url: item.hasLink,
                        })
                      }}
                      className='mb-0 text-grayscale--500'
                      dangerouslySetInnerHTML={{ __html: item.text }}
                    />
                ) : (
                  <div>
                    <span
                      className='mb-0 text-grayscale--500 fs-14 lh-17 fs-lg-16 lh-lg-20 text-grayscale--500'
                      dangerouslySetInnerHTML={{ __html: item.text }}
                    />
                    {item.hasTooltip && (<span className='cursor-pointer tooltip--svg ml-2 ml-lg-0 position-relative'><ToolTip /></span>)}
                  </div>
                )}
                </DataContent>
            ))}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Advantages
