import React, { useState } from 'react'
import Layout from 'src/layouts/BaseLayout'
import useDomReady from 'src/hooks/window/useDomReady'
import { Modal } from 'src/components/Modal'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'

import Header from './sections/header/_header'
import Advantages from './sections/advantages/_index'
import ExploreMercado from './sections/explore-o-mercado/_explore-o-mercado'
import Acompanhe from './sections/acompanhe/_acompanhe'
import CarouselInvestiments from './sections/CarrouselInvestiments/_index'
import FiquePorDentro from './sections/fique-por-dentro/_fique-por-dentro'
import FaqComponent from './sections/faq/_index'
import Disclaimer from './sections/Disclaimer/_index'
import QrCode from './assets/qr-code-investimentos-plataformaglobal.png'

// import AcesseHomeBrokerInternacional from './assets/home-broker-international-qr-code.png'

import pageContext from './pageContext.json'

import { Wrapper } from './style'

const HomeBrokerInternational = () => {
  const domReady = useDomReady()
  const [ isOpen, setIsOpen ] = useState(false)

  const cardsModal = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <QrCodeModal
        isModal={isOpen}
        setIsModal={setIsOpen}
        title='Escaneie o QR Code, acesse o app, abra sua conta Apex e invista'
        instructions='Ligue a câmera do seu celular e aponte para o QR Code acima. Você será redirecionado para o ambiente Apex dentro do app.'
        qrCode={QrCode}
      />
    </Modal>
  )

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        {cardsModal}
        <Header setIsOpen={setIsOpen} />
        <Advantages />
        <ExploreMercado />
        <Acompanhe />
        <CarouselInvestiments />
        <FiquePorDentro />
        <FaqComponent />
        <Disclaimer />
      </Layout>
    </Wrapper>
  )
}

export default HomeBrokerInternational
